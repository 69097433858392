<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="users.usersData">
      <div class="row">
        <UserCard
          v-for="(user, index) in users.usersData"
          :key="index"
          :user="user"
          :defaultImg="users.user.defaultImg"
          v-on:setUserData="users.user.fillData($event)"
        />
      </div>

      <ActionsData :actionsData="users.user" />
      <UserInfo :user="users.user" />
      <UserDelete :user="users.user" v-on:refresh="getAllUsers()" />

      <Pagination
        v-if="!isLoading"
        :paginationData="users.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <UserFloatBtns
      :theFilterData="users.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import UserCard from "@/components/users/UserCard.vue";
import UserInfo from "@/components/users/UserInfo.vue";
import UserDelete from "@/components/users/UserDelete.vue";
import UserFloatBtns from "@/components/users/UserFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Users from "./../../models/users/Users";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    UserCard,
    UserInfo,
    UserDelete,
    UserFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      users: new Users(),
    };
  },
  methods: {
    changePage(page) {
      this.users.pagination.selfPage = page;
      this.getAllUsers();
    },
    search(data) {
      this.users.filterData.fillData(data);
      this.users.usersData = [];
      this.getAllUsers();
    },
    async getAllUsers() {
      this.isLoading = true;
      this.users.filterData.userTypeToken = this.$route.meta.userTypeToken;
      try {
        const response = await this.users.user.getAllUsers(
          this.language,
          this.userAuthorizeToken,
          this.users.pagination,
          this.users.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.users.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.users.usersData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.users.usersData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.users.usersData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.users.usersData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllUsers();
  },
};
</script>
